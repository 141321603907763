@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
/*!Don't remove this!
 * Material-Toast plugin styles
 * 
 * Author: Dionlee Uy
 * Email: dionleeuy@gmail.com
 */
.mdtoast {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 24px;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  text-align: left;
  outline: none;
  pointer-events: auto;
  touch-action: auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #323232;
  transform: translateY(0);
  transition: transform 0.23s 0ms cubic-bezier(0, 0, 0.2, 1);
  will-change: transform;
  z-index: 100002;
}

.mdtoast[data-position="bottom left"] {
  left: 24px;
  bottom: 24px;
}

.mdtoast[data-position="bottom center"] {
  bottom: 24px;
  left: 50%;
  transform: translateY(0) translateX(-50%);
}

.mdtoast[data-position="bottom right"] {
  right: 24px;
  bottom: 24px;
}

.mdtoast[data-position="top left"] {
  top: 24px;
  left: 24px;
}

.mdtoast[data-position="top center"] {
  top: 24px;
  left: 50%;
  transform: translateY(0) translateX(-50%);
}

.mdtoast[data-position="top right"] {
  top: 24px;
  right: 24px;
}

.mdtoast .mdt-message {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 8px 0;
  opacity: 1;
  margin-left: 0;
  box-sizing: border-box;
  transition: opacity 0.3s 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdtoast .mdt-message::after {
  content: '';
  min-height: 32px;
  font-size: 0;
}

.mdtoast .mdt-action {
  display: flex;
  align-items: center;
  color: #ffeb3b;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 0.07em;
  font-weight: 500;
  padding: 8px;
  margin: 0 0 0 24px;
  opacity: 1;
  min-height: 32px;
  background: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  border-radius: 4px;
  transition: opacity 0.3s 0ms cubic-bezier(0.4, 0, 1, 1), background-color 0.15s linear;
}

.mdtoast .mdt-action:focus, .mdtoast .mdt-action:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

.mdtoast .mdt-action:active {
  background-color: rgba(255, 255, 255, 0.15);
}

.mdtoast.mdt--load {
  transition: transform 0.23s 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdtoast.mdt--load[data-position*='bottom'] {
  transform: translateY(150%);
}

.mdtoast.mdt--load[data-position*='top'] {
  transform: translateY(-150%);
}

.mdtoast.mdt--load .mdt-message {
  opacity: 0;
}

.mdtoast.mdt--load .mdt-action {
  opacity: 0;
}

.mdtoast.mdt--interactive {
  padding-right: 16px;
}

.mdtoast.mdt--interactive .mdt-message {
  margin-right: auto;
}

.mdtoast.mdt--info {
  background-color: #1565c0;
}

.mdtoast.mdt--error {
  background-color: #e53935;
}

.mdtoast.mdt--warning {
  background-color: #ef6c00;
}

.mdtoast.mdt--success {
  background-color: #2e7d32;
}

@media (min-width: 600px) {
  .mdtoast {
    min-width: 288px;
    max-width: 568px;
    border-radius: 4px;
  }
  .mdtoast.mdt--load[data-position='bottom center'] {
    transform: translateY(150%) translateX(-50%);
  }
  .mdtoast.mdt--load[data-position='top center'] {
    transform: translateY(-150%) translateX(-50%);
  }
}

@media (max-width: 599px) {
  .mdtoast {
    left: 0 !important;
    right: 0 !important;
    font-size: 14px;
    max-width: 100%;
    transform: translateY(0);
  }
  .mdtoast[data-position*='bottom'] {
    bottom: 0;
    transform: translateY(0);
  }
  .mdtoast[data-position*='top'] {
    top: 0;
    transform: translateY(0);
  }
  .mdtoast.mdt--load {
    transform: translateY(100%);
  }
}

body.mdtoast--modal {
  pointer-events: none;
  touch-action: none;
  -ms-user-select: none;
  -webkit-user-select: none;
          user-select: none;
}

